/* App.css */

body {
  font-family: 'Amazon Ember', sans-serif; /* Use the Amber font family */
 
  background-color: #f0f0f0; /* Amazon-like background color */
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}
.dashboard {
  overflow-y: auto;
  display: flex; /* Use Flexbox to create a flexible layout */
  flex-wrap: wrap; 
  min-height: 100vh;

}





.sidebar h1 {
  margin-bottom: 20px;
}


.sidebar-img {
  width: 10px; /* Limit the width to the container's width */
  height: 10px; /* Limit the height to a specific value */
  display: block; /* Remove any inline spacing */
  margin: 0 auto; /* Center the image horizontally */
}
html, body {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensure full height */
}

.container {
  display: flex;
  flex-direction: column; /* or row */
  height: 100%; /* Full height */
}

.content {
  flex: 1;
  padding: 0;
  background-color: #f5f5f5;
}
.content1 {
  flex: 1;
  padding: 10px;
  background-color: #f5f5f5;
}

/* ... (existing styles) ... */

/* Sidebar Styles */
.sidebar {
 
  width: 318px;
  background-color: #232f3e;
  color: white;
  padding: 20px;
  text-align: left;

  overflow-y: auto; 
 
  font-family: 'Amazon Ember', sans-serif; 
}






.sidebar h1 {
  margin-bottom: 20px;
  text-align:left;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav li {
  margin-bottom: 10px;
}


nav a {
  color: #ff9900; /* Amazon blue color */
  font-family: 'Amazon Ember', sans-serif; /* Amazon font family */
  text-decoration: none;
  padding: 5px 10px;
  display: inline-block;
  transition: color 0.3s ease-in-out; /* Smooth color transition on hover */
}

/* Style for the navigation list items */
nav ul li {
  margin-bottom: 0px; /* Add spacing between list items */
}

/* Hover effect for list items */
nav ul li a:hover  {
  color: #0066c0; /* Amazon-like color on hover */
}
/*
nav a {
  color: white;
  font-family: 'Amber', sans-serif; 
  text-decoration: none;
  display: block;
  padding: 5px 10px;
}



nav a:hover {
  background-color: #FF9900;
}*/

.user-profile {
  display: flex;
  align-items: left;

}

.user-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
/* ---------------- */

/* Style for the "Tuttu" option */
/* Style for the external link */
.external-link {
  color: #0073e6; /* Link text color */
  text-decoration: none; /* Remove underline */
  display: flex; /* Display link and icon horizontally */
  align-items: center; /* Vertically center the link and icon */
}

/* Style for the Font Awesome icon */
.fa {
  margin-left: 5px; /* Add spacing between text and icon */
  font-size: 18px; /* Adjust the icon size */
}


/* MainMenu.css */

/* MainMenu.css */

.main-menu {
  background-color: #232f3e; /* Background color for the menu bar */
  color: white;
  padding: 10px 0; /* Add padding for spacing */
  display: flex; /* Use flex layout */
  justify-content: space-between; /* Move menu items to each end */
}

.main-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-menu li {
  margin-right: 20px; /* Add spacing between menu items */
}

.main-menu a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

/* Reset default margin and padding */


body, h1, h2, h3, p {
    margin: 0;
    padding: 0;
}

/* Style for the top bar */
.top-bar {
  width: auto;
  background-color: #232f3e;
  color: white;
  display: flex;
  justify-content: flex-end; /* Aligns items to the right */
  align-items: center;
  padding: 10px 20px;
}

/* Style for the Amazon logo */
.logo img {
    width: 100px;
    height: auto;
}

/* Style for the "Sign In" link */
.sign-in a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 14px;
    padding: 8px 16px;
    border: 1px solid white;
    border-radius: 3px;
    transition: background-color 0.3s ease-in-out;
}

.sign-in a:hover {
    background-color: #E68500; /* Amazon-like button color on hover */
}

/* Center the top bar text vertically */
.top-bar div {
    display: flex;
    align-items: center;
  
}


/* STATUS CSS */

.green-icon {
  color: green;
}


.amber-icon {
  color: #E68500;
}
.gray-icon {
  color: gray;
}

.blue-icon {
  color: rgb(0, 183, 255);
}




/* Style for the tooltip */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip::before {
  content: attr(data-status); /* Display the status as tooltip text */
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7); /* Background color for the tooltip */
  color: #fff; /* Text color for the tooltip */
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  opacity: 0; /* Initially, the tooltip is hidden */
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; /* The tooltip should not interfere with hover events */
}

/* Show the tooltip on hover */
.tooltip:hover::before {
  opacity: 1;
}


/* Add styles for the footer */
/* Add styles for the sticky footer */
.sticky-footer {
  text-align: left;
  position: fixed;
  bottom: 0;
  width: 100%;
  font-size: 14px; /* You can adjust the font size as needed */
}


/* Add styles for the feedback section */
.feedback-section {
  
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5; /* Amazon-like background color */
  padding: 20px;
  border: 1px solid #ddd;
  margin-top: 20px;
}



.feedback-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.comments-count {
  color: #555;
}

.feedback-content {
  display: flex;
  flex-direction: row; /* Display components horizontally */
  flex-wrap: wrap; /* Wrap components to the next line if needed */
  gap: 20px; /* Add spacing between components */
}

.feedback-item {
  flex: 1; /* Each component takes equal space */
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  text-align: center; /* Center align the content */
}

.feedback-label {
  font-weight: bold;
}

.feedback-value {
  color: #0073e6; /* Amazon-like link color */
  cursor: pointer;
}



/* Add this CSS to your App.css file */

.submenu {
  font-size: 15px;
  line-height: 1/* Font size for submenu */
}

.submenu > ul {
  padding: 0;
  margin: 0;
}

.submenu > ul > li {
  list-style-type: none;
  line-height: 1
}

.submenu > ul > li > .sidebar-link {
  display: block;
  margin-bottom: 0px;
}

.submenu > ul > li > a {
  display: block;
  margin-bottom: 3px;
  font-size: 8px; 
  line-height: 1;/* Font size for links within submenu */
}

.subsubmenu {
  font-size: 14px; /* Font size for subsubmenu */
}

.subsubmenu > ul {
  padding: 0;
  margin: 0;
}

.subsubmenu > ul > li {
  list-style-type: none;
}

.subsubmenu > ul > li > .sidebar-link {
  display: block;
  margin-bottom: 3px;
}

.subsubmenu > ul > li > a {
  display: block;
  margin-bottom: 3px;
  /* Font size for links within subsubmenu */
}






/* Amazon Form Styles */

.amazon-form {
  font-family: Arial, sans-serif;
}

.amazon-select {
  width: 200px;
  padding: 8px;
  margin-bottom: 10px;
}

.amazon-checkbox-label {
  display: block;
  margin-bottom: 5px;
}

.amazon-checkbox {
  margin-right: 5px;
}

.amazon-button {
  padding: 10px 20px;
  background-color: #ff9900;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.amazon-button:hover {
  background-color: #e68a00;
}

/* Additional Styles */
/* Add any additional styles you need here */
.validation-result-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer; /* to make it look clickable */
}


/* Adjust the form fields to be side by side */
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-right: 15px;
  padding-left: 15px;
}

/* Adjust layout for smaller screens */
@media (max-width: 768px) {
  .col-md-4 {
      flex: 0 0 100%;
      max-width: 100%;
  }
}
thead {
  background-color: #f0f0f0; /* Background color */
}

thead th {
  padding: 10px 15px; /* Padding for table header cells */
  text-align: left; /* Text alignment */
  border-bottom: 2px solid #ccc; /* Bottom border */
}

/* Optional: Hover styles for table header cells */
thead th:hover {
  background-color: #e0e0e0; /* Background color on hover */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it’s on top */
}

.loading-overlay p {
  color: white; /* Text color */
  font-size: 24px;
}

.help-link {
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
}

.help-link:hover {
  text-decoration: underline;
}



.header {
  text-align: left;
  display: flex;
  align-items: center;
}

.header-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
}

.logo {
  width: 60px;
  height: auto;
  margin-right: 8px; /* Adds space between the logo and text */
}
